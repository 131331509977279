import React from "react"

import {
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Box,
} from "@chakra-ui/react"

import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons"
import IconChevronUp from "../Icons/IconChevronUp"
import IconChevronDown from "../Icons/IconChevronDown"

const SortMenu = ({ name, items, itemsID, setFunction, sortBy, disabled }) => {
  return (
    <Menu>
      {({ isOpen }) => (
        <>
          <MenuButton
            as={Button}
            rightIcon={
              isOpen ? (
                <Box width="24px" mt="3px" mr="-5px" ml="-5px">
                  <IconChevronUp />
                </Box>
              ) : (
                <Box width="24px" mr="-5px" ml="-5px">
                  <IconChevronDown />
                </Box>
              )
            }
            colorScheme="gray"
            variant="ghost"
            border="0px"
            color="black"
            borderColor="modeGray.100"
            bgColor="transparent"
            borderRadius="full"
            disabled={disabled}
          >
            {sortBy.name ? sortBy.name : name}
          </MenuButton>
          <MenuList>
            {items.map((item, index) => {
              return (
                <MenuItem
                  key={item.name + index}
                  onClick={() => {
                    setFunction(item)
                  }}
                >
                  {item.name}
                </MenuItem>
              )
            })}
          </MenuList>
        </>
      )}
    </Menu>
  )
}

export default SortMenu
