import React, { useEffect, useMemo, useState } from "react"

import { Box, Flex, Button, Text } from "@chakra-ui/react"

import ListGrid from "./ListGrid"
import ListList from "./ListList"

import { filterForschung } from "../../tools/filterForschung"

const ForschungsResults = ({
  allForschung,
  selectedFilterArray,
  listView,
  sortBy,
  stringData,
  search,
}) => {
  console.log("listView", listView)

  const [currentPage, setCurrentPage] = useState(0)

  useEffect(() => {
    // RESET PAGE ON FILTER CHANGE
    setCurrentPage(0)
  }, [allForschung, selectedFilterArray, search, sortBy])

  const filteredForschung = useMemo(
    () => filterForschung(allForschung, selectedFilterArray, search, sortBy),
    [allForschung, selectedFilterArray, search, sortBy]
  )

  const entriesPerPage = 9

  let paginatedForschung = useMemo(() => {
    let i
    let j
    let chunkedArrayOfForschung = []

    // Split to Chunks
    for (i = 0, j = filteredForschung.length; i < j; i += entriesPerPage) {
      chunkedArrayOfForschung.push(
        filteredForschung.slice(i, i + entriesPerPage)
      )
    }
    return chunkedArrayOfForschung
  }, [filteredForschung])

  const pagination = new Array(paginatedForschung.length).fill(0)

  console.log("temp", paginatedForschung)
  console.log("ddd", pagination)

  // DO SOME FILTER MAGIC HERE
  return (
    <Box>
      {paginatedForschung.length !== 0 ? (
        <Box>
          {listView === "grid" ? (
            <ListGrid
              results={paginatedForschung[currentPage]}
              stringData={stringData}
            />
          ) : (
            <ListList
              results={paginatedForschung[currentPage]}
              stringData={stringData}
            />
          )}
        </Box>
      ) : (
        <Text
          fontWeight="bold"
          width="100%"
          my="16px"
          mt="40px"
          textAlign="center"
        >
          {stringData.acf_filter_settings.keineForschungsprojekteGefunden}
        </Text>
      )}

      <Flex justifyContent="center" mt="24px">
        {pagination.length > 1
          ? pagination.map((page, index) => {
              return (
                <Button
                  key={`page ${index + 1}`}
                  onClick={() => {
                    setCurrentPage(index)
                  }}
                  mr="16px"
                  borderRadius="90px"
                  p="0px"
                  background="transparent"
                  _active={{
                    background: "white",
                  }}
                  _hover={{
                    background: "rgba(255,255,255,75%)",
                  }}
                  isActive={index === currentPage}
                >
                  {index + 1}
                </Button>
              )
            })
          : ""}
      </Flex>
    </Box>
  )
}

export default ForschungsResults
