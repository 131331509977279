import React, { useMemo } from "react"

import {
  Box,
  Button,
  Collapse,
  useDisclosure,
  Stack,
  Spacer,
  IconButton,
} from "@chakra-ui/react"
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons"

import IconViewList from "../Icons/IconViewList"
import IconViewGrid from "../Icons/IconViewGrid"
import FilterSelects from "./FilterSelects"
import SelectedFilter from "./SelectedFilter"
import SortMenu from "./SortMenu"
import Suche from "./Suche"

import {
  getSchwerpunkte,
  getKeywords,
  getYears,
  getNames,
  getDisciplines,
  getCategories,
} from "../../tools/filterForschung"
import { useSelector } from "react-redux"
import IconChevronUp from "../Icons/IconChevronUp"
import IconChevronDown from "../Icons/IconChevronDown"

const FilterBar = ({
  selectedFilterArray,
  setSelectedFilterArray,
  listView,
  setListView,
  sortBy,
  setSortBy,
  search,
  setSearch,
  forschung,
  stringData,
}) => {
  let filterSettings = stringData.acf_filter_settings
  const CURRENT_LOCALE = useSelector(state => state.currentLocaleID)

  const { isOpen, onToggle } = useDisclosure()

  let schwerpunktthemen = useMemo(() => getSchwerpunkte(forschung), [forschung])
  let keywords = useMemo(
    () => getKeywords(forschung, CURRENT_LOCALE),
    [forschung, CURRENT_LOCALE]
  )
  let years = useMemo(() => getYears(forschung), [forschung])
  let names = useMemo(() => getNames(forschung), [forschung])
  let disciplines = useMemo(
    () => getDisciplines(forschung, CURRENT_LOCALE),
    [forschung, CURRENT_LOCALE]
  )
  let categories = useMemo(
    () => getCategories(forschung, CURRENT_LOCALE),
    [forschung, CURRENT_LOCALE]
  )

  console.log("CURRENT_LOCALE", CURRENT_LOCALE)

  return (
    <Box borderRadius="16px" background="white" padding="16px">
      {/* Suche and Filter anzeigen */}
      <Stack direction={["column", "column", "column", "row", "row"]}>
        <Stack direction={["column", "column", "row", "row", "row"]}>
          <Suche setSearch={setSearch} stringData={filterSettings} />

          <Button
            variant="ghost"
            colorScheme="gray"
            borderRadius="full"
            isActive={isOpen}
            rightIcon={
              isOpen ? (
                <Box width="24px" mt="3px" mr="-5px" ml="-5px">
                  <IconChevronUp />
                </Box>
              ) : (
                <Box width="24px" mr="-5px" ml="-5px">
                  <IconChevronDown />
                </Box>
              )
            }
            onClick={() => {
              onToggle()
            }}
          >
            {isOpen
              ? filterSettings.filterSchliesen
              : filterSettings.filterAnzeigen}
          </Button>
        </Stack>

        <Spacer />

        <Stack direction="row" spacing="16px">
          <SortMenu
            name={filterSettings.sortieren}
            items={[
              { name: "A-Z", id: "az" },
              { name: filterSettings.neusteZuerst, id: "newFirst" },
              { name: filterSettings.altesteZuerst, id: "oldFirst" },
            ]}
            setFunction={setSortBy}
            sortBy={sortBy}
            disabled={selectedFilterArray.length === 0 && search === ""}
          />
          <Stack direction="row">
            <IconButton
              borderRadius="full"
              variant="ghost"
              color={
                (selectedFilterArray.length === 0 && search === "") ||
                listView === "grid"
                  ? ""
                  : "gray.400"
              }
              disabled={selectedFilterArray.length === 0 && search === ""}
              aria-label="Grid / Raster"
              icon={<IconViewGrid className="w-5" />}
              onClick={() => {
                setListView("grid")
              }}
            />

            <IconButton
              borderRadius="full"
              variant="ghost"
              color={
                (!selectedFilterArray.length === 0 && search === "") ||
                listView === "list"
                  ? ""
                  : "gray.400"
              }
              disabled={selectedFilterArray.length === 0 && search === ""}
              aria-label="List / Liste"
              icon={<IconViewList className=" w-5" />}
              onClick={() => {
                setListView("list")
              }}
            />
          </Stack>
        </Stack>
      </Stack>

      <Collapse in={isOpen} animateOpacity>
        <FilterSelects
          setSelectedFilterArray={setSelectedFilterArray}
          selectedFilterArray={selectedFilterArray}
          schwerpunktthemen={schwerpunktthemen}
          keywords={keywords}
          years={years}
          names={names}
          disciplines={disciplines}
          categories={categories}
          stringData={filterSettings}
        />
      </Collapse>

      {/* Selected Filter */}
      <SelectedFilter
        setSelectedFilterArray={setSelectedFilterArray}
        selectedFilterArray={selectedFilterArray}
        stringData={filterSettings}
      />
    </Box>
  )
}

export default FilterBar
