import React, { useState } from "react"

import {
  Box,
  Button,
  Input,
  Collapse,
  useDisclosure,
  Stack,
  Spacer,
  IconButton,
  Flex,
  Text,
  Heading,
  AspectRatio,
  Image,
  Link,
} from "@chakra-ui/react"
import {
  ChevronDownIcon,
  ChevronUpIcon,
  SmallCloseIcon,
  DeleteIcon,
} from "@chakra-ui/icons"
import { useSelector } from "react-redux"
import useGenUrl from "../../hooks/useGenUrl"
import IconChevronUp from "../Icons/IconChevronUp"
import IconChevronDown from "../Icons/IconChevronDown"

const ListList = ({ forschung, slug, stringData }) => {
  const [beschreibungIsOpen, setBeschreibungIsOpen] = useState(false)
  const [linkIsOpen, setLinkIsOpen] = useState(false)

  const CURRENT_LOCALE = useSelector(state => state.currentLocaleID)

  let url = useGenUrl(slug, "wissenschaft", CURRENT_LOCALE)

  console.log("stringData", stringData)
  return (
    <Box
      mb="16px"
      borderColor="modeGray.50"
      borderRadius="16px"
      backgroundColor="white"
      _hover={{
        transform: "translateY(-6px)",
        boxShadow: "lg",
        transition: "all 0.2s",
      }}
      transition="all 0.1s ease-out"
    >
      <Flex
        boxSizing="border-box"
        w={["full", "full", "full"]}
        overflow="hidden"
        border={"0px"}
        borderColor="modeGray.50"
        borderRadius="16px"
        minHeight="160px"
        position="relative"
        backgroundColor="white"
        direction={["column", "column", "column", "row"]}
        paddingBottom={beschreibungIsOpen || linkIsOpen ? "32px" : "0px"}
        transition="all 0.1s ease-out"
      >
        <Box width="320px">
          <Link href={url}>
            {forschung?.acf_forschung?.imageFeatureImage ? (
              <AspectRatio
                style={
                  beschreibungIsOpen || linkIsOpen
                    ? {
                        margin: "16px",
                        borderRadius: "16px",
                      }
                    : {}
                }
                transition="all 0.2s ease-out"
                ratio={[21 / 9, 21 / 9, 21 / 9, 16 / 9, 16 / 9]}
                width={
                  beschreibungIsOpen || linkIsOpen
                    ? ["auto", "auto", "auto", "90%", "90%"]
                    : ["auto", "auto", "auto", "100%", "100%"]
                }
                height={["50%", "50%", "50%", "100%", "100%"]}
              >
                <Image
                  objectFit="cover"
                  transition="all 0.2s ease-out"
                  borderRadius={
                    beschreibungIsOpen || linkIsOpen ? "16px" : "0px"
                  }
                  src={
                    forschung?.acf_forschung?.imageFeatureImage.localFile
                      .childImageSharp.fluid.src
                  }
                  srcSet={
                    forschung?.acf_forschung?.imageFeatureImage.localFile
                      .childImageSharp.fluid.srcSet
                  }
                  alt={forschung?.acf_forschung?.imageFeatureImage.altText}
                />
              </AspectRatio>
            ) : (
              <AspectRatio ratio={16 / 9}>
                <Box background="modeGray.50" />
              </AspectRatio>
            )}
          </Link>
        </Box>

        <Flex
          direction="column"
          width={["70%", "70%", "70%", "70%"]}
          pl="8px"
          pt="24px"
          pb="24px"
        >
          <Box pt="8px" pl="24px">
            <Heading color="modeGray.500" mb="8px" fontSize="sm">
              {forschung?.acf_forschung?.groupProjektbeteiligte?.textAutoren
                .length > 0 &&
                forschung?.acf_forschung?.groupProjektbeteiligte?.textAutoren.map(
                  (i, k) => {
                    if (
                      k ===
                      forschung?.acf_forschung?.groupProjektbeteiligte
                        ?.textAutoren.length -
                        1
                    ) {
                      return i.name
                    } else {
                      return `${i.name}, `
                    }
                  }
                )}
            </Heading>
            <Heading as="h3" size="lg" mb="8px">
              <Link href={url}>{`-> ${forschung?.title}`}</Link>
            </Heading>
            {forschung?.acf_forschung?.groupProjektbeteiligte
              ?.textInstitution ? (
              <Text mt="16px">
                <Text fontWeight="semibold" d="inline">
                  {`${stringData?.acf_research_settings?.forschungsbegriffe?.institution}: `}
                </Text>
                {
                  forschung?.acf_forschung?.groupProjektbeteiligte
                    ?.textInstitution
                }
              </Text>
            ) : (
              ""
            )}
          </Box>
          <Spacer />

          <Flex justifyContent="flex-start" wrap="wrap" mt="24px">
            <Button
              variant="ghost"
              colorScheme="gray"
              borderRadius="full"
              isActive={beschreibungIsOpen}
              rightIcon={
                beschreibungIsOpen ? (
                  <Box width="24px" mt="3px" mr="-5px" ml="-5px">
                    <IconChevronUp />
                  </Box>
                ) : (
                  <Box width="24px" mr="-5px" ml="-5px">
                    <IconChevronDown />
                  </Box>
                )
              }
              onClick={() => {
                setLinkIsOpen(false)
                setBeschreibungIsOpen(!beschreibungIsOpen)
              }}
            >
              {stringData?.acf_research_settings?.projektbeschreibung}
            </Button>

            {forschung?.acf_forschung?.links ? (
              <Button
                variant="ghost"
                colorScheme="gray"
                borderRadius="full"
                isActive={linkIsOpen}
                rightIcon={
                  linkIsOpen ? (
                    <Box width="24px" mt="3px" mr="-5px" ml="-5px">
                      <IconChevronUp />
                    </Box>
                  ) : (
                    <Box width="24px" mr="-5px" ml="-5px">
                      <IconChevronDown />
                    </Box>
                  )
                }
                onClick={() => {
                  setLinkIsOpen(!linkIsOpen)
                  setBeschreibungIsOpen(false)
                }}
              >
                Links
              </Button>
            ) : (
              ""
            )}
          </Flex>
          <Box>
            <Collapse in={beschreibungIsOpen} animateOpacity>
              <Text pt="16px" px="24px" maxWidth="640px">
                {
                  forschung?.acf_forschung?.projektbeschreibung
                    ?.textProjektbeschreibungText
                }{" "}
              </Text>
            </Collapse>
            <Collapse in={linkIsOpen} animateOpacity>
              <Flex direction="column">
                {forschung?.acf_forschung?.links?.map((link, index) => {
                  // TODO:API: ALT TEXT?
                  return (
                    <Link
                      pt="16px"
                      px="24px"
                      isExternal
                      key={link.url + index}
                      href={link.url}
                    >
                      {`-> ${link.url}`}
                    </Link>
                  )
                })}
              </Flex>
            </Collapse>
          </Box>
        </Flex>
      </Flex>
    </Box>
  )
}

export default ListList
